import "alpinejs";
import { Module } from "@/helper/loadModules";

const modules: Module[] = [
  {
    id: "mobile-header",
    loadModule: () =>
      import(
        /* webpackChunkName: "mobile-header.module" */ "@/modules/mobile-header.module"
      ),
  },
  {
    id: "search",
    loadModule: () =>
      import(/* webpackChunkName: "search.module" */ "@/modules/search.module"),
  },
  {
    id: "prototype",
    loadModule: () =>
      import(
        /* webpackChunkName: "prototype.module" */ "@/modules/prototype.module"
      ),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(
        /* webpackChunkName: "lightbox.module" */ "@/modules/lightbox.module"
      ),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(/* webpackChunkName: "swiper.module" */ "@/modules/swiper.module"),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(/* webpackChunkName: "scroll.module" */ "@/modules/scroll.module"),
  },
  {
    loadGlobal: true,
    loadModule: () =>
      import(/* webpackChunkName: "lottie.module" */ "@/modules/lottie.module"),
  },
];

export default modules;
